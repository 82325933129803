.aboutusHome {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.aboutusHome {
  display: flex;
  flex-direction: row;
}
.aboutusHomeContent {
  width: 44%;
  margin-top: 2%;
  margin-left: 4%;
}
.aboutusHomeImage {
  width: 48%;
  margin-top: 2%;
}
.aboutusHomeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.aboutusHomeContentHeadingA {
  color: #ff6c2c;
  letter-spacing: 3px;
  font-size: 20px;
  font-weight: 600;
  font: "Futura PT";
  padding-bottom: 2%;
  padding-top: 3%;
}
.aboutusHomeContentHeadingB {
  color: #212e61;

  font-size: 40px;
  letter-spacing: 0px;
  opacity: 1;
  padding-bottom: 2%;
  font-weight: 600;
}

.aboutusHomeContentline {
  width: 15%;
  height: 2px;
  background-color: skyblue;
}
.aboutusHomeContentHeadingC {
  padding-top: 2%;
  color: #ff6c2c;
  font-size: 30px;

  font-weight: 600;
  padding-bottom: 1%;
}
.aboutusHomeContentDataPara {
  width: 80%;
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #9e9e9e;
  font-weight: 600;
}
.aboutusHomeImage {
  padding-top: 3%;
  display: flex;
  flex-direction: column;
}
.aboutusHomeImage .qrcodeTable {
  width: 50%;
  height: 60%;
  position: relative;
}
.aboutusHomeImage .qrcodeTable .aboutusHomeImageQrCodeTable {
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: absolute;
}
.aboutusHomeImage .shotwoman {
  width: 50%;
  height: 60%;
  position: relative;
  z-index: 3;
}
.aboutusHomeImage .shotwoman .aboutusHomeImageShotWoman {
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: absolute;
  right: -70%;

  bottom: 30%;
}
.aboutusHomeImage .personscanningqr {
  max-width: 50%;
  height: 60%;
  position: relative;
}
.aboutusHomeImage .personscanningqr .aboutusHomeImagePersonScanningQr {
  object-fit: fill;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 50%;
}
.aboutuswhoositimage {
  width: 100%;
  height: 90%;
}
.whoositaboutimage {
  width: 100%;
  height: 100%;
}
@media (max-width: 1378px) {
  .aboutusHome {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 1024px) {
  .aboutusHome {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .aboutusHomeContent,
  .aboutusHomeImage {
    width: 96%;
    margin-top: 2%;
    margin-left: 5%;
    align-items: left;
    height: 100%;
  }
  .aboutusHomeImage {
    margin-left: 4%;
  }

  .aboutusHomeImage {
    padding-top: 3%;
    display: flex;
    flex-direction: column;
  }

  .aboutusHomeContentDataPara {
    width: 98%;
    font-size: 20px;
    
  }

  .aboutusHomeImage .qrcodeTable,
  .aboutusHomeImage .shotwoman,
  .aboutusHomeImage .personscanningqr {
    width: 50%;
    height: 300px;
    position: relative;
  }

  .aboutusHomeImage .qrcodeTable .aboutusHomeImageQrCodeTable,
  .aboutusHomeImage .shotwoman .aboutusHomeImageShotWoman,
  .aboutusHomeImage .personscanningqr .aboutusHomeImagePersonScanningQr {
    position: absolute;
  }
}

@media (max-width: 760px) {
  .aboutusHome {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .aboutusHomeContent,
  .aboutusHomeImage {
    width: 96%;
    margin-top: 1%;
    margin-left: 5%;
    align-items: left;
    height: 100%;
  }
  .aboutusHomeImage {
    margin-left: 4%;
  }

  .aboutusHomeImage {
    padding-top: 3%;
    display: flex;
    flex-direction: column;
  }

  .aboutusHomeContentDataPara {
    width: 90%;
    font-size: 18px;
  }

  .aboutusHomeImage .qrcodeTable,
  .aboutusHomeImage .shotwoman,
  .aboutusHomeImage .personscanningqr {
    width: 60%;
    height: 200px;
    position: relative;
  }

  .aboutusHomeImage .qrcodeTable .aboutusHomeImageQrCodeTable,
  .aboutusHomeImage .shotwoman .aboutusHomeImageShotWoman,
  .aboutusHomeImage .personscanningqr .aboutusHomeImagePersonScanningQr {
    position: absolute;
  }

  .aboutusHomeImage .shotwoman .aboutusHomeImageShotWoman {
    object-fit: fill;
    width: 100%;
    height: 100%;
    position: absolute;
    right: -60%;
    bottom: 30%;
  }
  .aboutusHomeImage {
    margin-right: 2%;
    margin-left: 2%;
  }
}

@media (max-width: 580px) {
  .aboutusHomeContentHeadingA {
    font-size: 14px;
    font-weight: 600;
  }

  .aboutusHomeContentHeadingB {
    font-size: 20px;
  }
  .aboutusHomeContentline {
    width: 20%;
    height: 2px;
    background-color: skyblue;
  }
  .aboutusHomeContentHeadingC {
    font-size: 22px;
  }
  .aboutusHomeContentDataPara {
    width: 90%;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #9e9e9e;
    font-weight: 600;
  }
  .aboutusHomeImage {
    margin-right: 2%;
    margin-left: 2%;
  }
}

@media (max-width: 768px) {
  .aboutusHome {
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .aboutusHomeContent,
  .aboutusHomeImage {
    width: 96%;
    margin-top: 1%;
    margin-left: 5%;
    align-items: left;
    height: 100%;
  }
  .aboutusHomeImage {
    margin-left: 4%;
  }

  .aboutusHomeImage {
    padding-top: 3%;
    display: flex;
    flex-direction: column;
  }

  .aboutusHomeContentDataPara {
    width: 90%;
    font-size: 18px;
  }

  .aboutusHomeImage {
    margin-right: 2%;
    margin-left: 2%;
  }
}

@media (max-width: 580px) {
  .aboutusHomeContentHeadingA {
    font-size: 14px;
    font-weight: 600;
  }

  .aboutusHomeContentHeadingB {
    font-size: 30px;
  }
  .aboutusHomeContentline {
    width: 20%;
    height: 2px;
    background-color: skyblue;
  }
  .aboutusHomeContentHeadingC {
    font-size: 25px;
  }
  .aboutusHomeContentDataPara {
    width: 90%;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #9e9e9e;
    font-weight: 600;
  }
  .aboutusHomeImage {
    margin-right: 2%;
    margin-left: 2%;
  }
}
@media (max-width: 460px){
  .aboutusHomeContentHeadingB {
    font-size: 22px;
  }
 
  .aboutusHomeContentHeadingC {
    font-size: 20px;
  }
}