.stickybar {
  position: fixed;
  top: 25%;
  background-color: #f6f7fb;

  z-index: 100000000;
}
.websticky,
.facebooksticky,
.twittersticky,
.instagramsticky {
  padding: 20px 10px;
  border-top: 1px solid skyblue;
  width: 18px;
  height: 18px;
}
.linkedinstickylogo {
  width: 100%;
  height: 100;
}
@media (max-width: 480px) {
  .stickybar {
    z-index: 100000000;
  }
  .websticky,
  .facebooksticky,
  .twittersticky,
  .instagramsticky {
    padding: 10px 10px;
    border-top: 1px solid skyblue;
    width: 15px;
    height: 15px;
  }
  .linkedinstickylogo {
    width: 100%;
    height: 100;
  }
}
