.features{
  margin: 2%;
  margin-top: 0%;
}
.featureshead{
  text-align: center;
  color:#212E61;
font-size: 40px;
margin-bottom: 0px;
font-weight: 500;
margin-top: 0;
}
.featuresline{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.featuresbluelines{
  width:10%;
  height:2px;
 background-color: skyblue;

}
.featuresContentA{
  text-align: center;
  color:#FF6C2C;
  opacity:80%;
  font-size: 35px;
  
  padding-top:2%;
  margin:0px;
 
}
.featuresContentB{
  text-align: center;
  color:#292F39;
  opacity:80%;
  font-size: 25px;
  margin:0px;
  padding-top: 0px;
  padding-bottom: 3%;
 
}
.sliderimagesperson , .sliderimagesyoung ,.sliderimagesside ,.sliderimagesbusiness ,.sliderimagesmedium ,.sliderimagesapplication ,.sliderimagesworking{
  width: 32%;
  height: 40%;
  margin: 0 10px; 
  padding: 10px;
  box-sizing: border-box;
}
.person ,.young ,.side ,.medium ,.business ,.application ,.working{
  width:100%;
  height:70%;
  object-fit: fill;
}

.featureqrscanningContent , .featureyoungmaleContent ,.featuresideviewwomanContent ,.featurebusinesspeopleContent ,.featuremediumshotwomanContent ,.featurebusinessapplicationContent ,.featurepeopleworkingContent{
  width:99.5%;
  text-align: center;
 
  padding:15px 0px;
  font-size: 25px;
  font-weight: 600;
  color: #292F39;
  /* border:1px solid #00000029; */
  box-shadow: 1px 1px  #00000029;
  
}


.carousel-outer-container {
  position: relative;
  margin: 0 50px;
}

.carousel-container {
  width: 100%;
  position: relative;
}


.custom-arrow-feature {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  outline: none;
  transition: background-color 0.3s;
  z-index: 1;
  background: transparent;
}

.custom-arrow :hover {
  background-color: #555;
}



.previousfeature {
  left: -35px;
 z-index: 40;
}

.nextfeature {
  right: -5%;
}
.arrowleftfeature ,.arrowrightfeature{
  height:30px;
  width:30px;
}
.unlockpremium{
display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding-top: 1%;
padding-bottom: 70px;

}
.unlockpremiumbutton{
  font-size: 20px;
  background-color: #212E61;
  color:white;
  text-decoration: underline;
  text-decoration-color: white;
  padding:10px 25px;
  border-radius: 5px;
  border-style: none;
  cursor:pointer;
}


.featureSlider{
  display:flex;
  align-items: center;
  justify-content: space-between; 
  padding: 20px 0;
}

@media(max-width:1368px){
  .featureqrscanningContent , .featureyoungmaleContent ,.featuresideviewwomanContent ,.featurebusinesspeopleContent ,.featuremediumshotwomanContent ,.featurebusinessapplicationContent ,.featurepeopleworkingContent{
  
    font-size: 20px;
   
    
  }
}

@media(max-width:1150px){
  .featureqrscanningContent , .featureyoungmaleContent ,.featuresideviewwomanContent ,.featurebusinesspeopleContent ,.featuremediumshotwomanContent ,.featurebusinessapplicationContent ,.featurepeopleworkingContent{
    font-size: 18px;
  }
  .featuresbluelines{
    width:15%;
    height:2px;
   background-color: skyblue;
  
  }
  .featuresContentA{
    
    font-size: 35px;

   
  }
  .featuresContentB{
   
    font-size: 20px;

   
  }
  .unlockpremiumbutton{
    font-size: 14px;
    padding:10px 20px;

  }
  .nextfeature {
    right: -5%;
  }
}
@media(max-width:1016px){
  .featureshead{
  font-size: 38px;
  }

  .nextfeature {
    right: -8%;
  }
}

@media (max-width: 768px) {
  .featuresContentA{
    font-size: 40px;
  }
  .featuresContentB{
    font-size: 30px;
  }
  .nextfeature {
    right: -10%;
  }
}

@media(max-width:580px){
  .featureqrscanningContent , .featureyoungmaleContent ,.featuresideviewwomanContent ,.featurebusinesspeopleContent ,.featuremediumshotwomanContent ,.featurebusinessapplicationContent ,.featurepeopleworkingContent{
    font-size: 14px;
  }
  .nextfeature {
    right: -15%;
  }
}
@media(max-width:530px){
  .featureqrscanningContent , .featureyoungmaleContent ,.featuresideviewwomanContent ,.featurebusinesspeopleContent ,.featuremediumshotwomanContent ,.featurebusinessapplicationContent ,.featurepeopleworkingContent{
    font-size: 18px;
  }
  .featuresbluelines{
    width:18%;
    height:2px;
   background-color: skyblue;
  
  }
  .featuresContentA{
    
    font-size: 24px;

   
  }
  .featuresContentB{
   
    font-size: 18px;

   
  }
  .unlockpremiumbutton{
    font-size: 12px;
    padding:10px 20px;

  }
  .nextfeature {
    right: -18%;
  }
}
@media(max-width:350px){
  .featureqrscanningContent , .featureyoungmaleContent ,.featuresideviewwomanContent ,.featurebusinesspeopleContent ,.featuremediumshotwomanContent ,.featurebusinessapplicationContent ,.featurepeopleworkingContent{
    font-size: 12px;
  }
  .nextfeature {
    right: -25%;
  }
}
@media(max-width:350px){
  .featureqrscanningContent , .featureyoungmaleContent ,.featuresideviewwomanContent ,.featurebusinesspeopleContent ,.featuremediumshotwomanContent ,.featurebusinessapplicationContent ,.featurepeopleworkingContent{
    font-size: 12px;
  }
  .nextfeature {
    right: -30%;
  }
  .unlockpremiumbutton{
    font-size: 12px;
    padding:10px 20px;

  }
}