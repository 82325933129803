.cardsSection{
    width:100%;
    height:auto;
    margin-top: 50px;
}
.cardsSection{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 1%;
}

.scanCard , .callCard , .connectCard{
    width:20%;
    height:25%;
    position:relative;
}

.scanCardImg , .callCardImg ,.connectCardImg{
    width:100%;
    height:100%;
    object-fit: fill;
    filter:brightness(50%);
    position:relative
}
.scanCardContent , .callCardContent , .connectCardContent{
    width:100%;
    height:inherit;
    position:absolute;
}
.scanCardContent, .callCardContent , .connectCardContent{
    display:flex;
    flex-direction: column;
 align-items: center;
    justify-content: center;
   top:35%;
 
}
.scanCardContentA , .callCardContentA ,.connectCardContentA{
    color:white;
    margin:0px;
    font-size: 20px;
    letter-spacing: 7px;
}
.scanCardContentB , .callCardContentB ,.connectCardContentB{
    color:white;
    margin:0px;
    padding:10px 20px;
    text-align: center;
}

.scanCardButton , .callCardButton ,.connectCardButton{
padding:5px 10px;
color:white;
text-decoration: underline;
text-decoration-color: white;
font-weight: medium;
border-style: none;
margin-top:3px;

}
.scanCardButton{
    background-color: #FF6C2C;
    
}
.callCardButton{
    background-color: #945B9F
}
.connectCardButton{
 background-color: #5EB6E1;
}

.cardSectionMobile{
    display:none
}


@media (max-width:1150px){

    .cardSectionMobile{
        display:none
    }

    .scanCard , .callCard , .connectCard{
        width:30%;
        height:35%;
        position:relative;
    }
    
    .scanCardImg , .callCardImg ,.connectCardImg{
        width:100%;
        height:100%;
        object-fit: fill;
        filter:brightness(50%);
        position:relative
    }
    .scanCardContent , .callCardContent , .connectCardContent{
        width:100%;
        height:inherit;
        position:absolute;
        font-size: 12px;
    }

}

@media (max-width:620px){
    .cardSectionMobile{
        display:none
    }
    .scanCard , .callCard , .connectCard{
        width:33%;
        height:48%;
        position:relative;
    }
    .scanCardContent, .callCardContent , .connectCardContent{
        display:flex;
        flex-direction: column;
     align-items: center;
        justify-content: center;
       top:25%;
     
    }
    
    .scanCardContentB ,.callCardContentB ,.connectCardContentB{
        padding:0px 10px
    }
    .scanCardImg , .callCardImg ,.connectCardImg{
        width:100%;
        height:100%;
        object-fit: fill;
        filter:brightness(50%);
        position:relative
    }
    .scanCardContent , .callCardContent , .connectCardContent{
        width:100%;
        height:inherit;
        position:absolute;
        font-size: 12px;
    } 
}


/* for mobile */
@media (max-width:480px){
    .cardsSection{
        display:none;
    }
    .cardSectionMobile{
        display:block;
       width:100%;
    margin-top:25px;
    margin-left: 50px;

    }
   



    .scanCardMobile , .callCardMobile , .connectCardMobile{
        width:70%;
        height:80%;
        position:relative;
    }
    .scanCardImgMobile , .callCardImgMobile ,.connectCardImgMobile{
        width:100%;
        height:100%;
        object-fit: fill;
        filter:brightness(50%);
        position:relative
    }
    .scanCardContentMobile , .callCardContentMobile , .connectCardContentMobile{
        width:100%;
        height:inherit;
        position:absolute;
    }
    .scanCardContentMobile, .callCardContentMobile , .connectCardContentMobile{
        display:flex;
        flex-direction: column;
     align-items: center;
        justify-content: center;
       top:10%;
     
    }
    .scanCardContentAMobile , .callCardContentAMobile ,.connectCardContentAMobile{
        color:white;
        margin:0px;
        letter-spacing: 7px;
    }
    .scanCardContentBMobile , .callCardContentBMobile ,.connectCardContentBMobile{
        color:white;
        margin:0px;
        padding:5px 5px;
        text-align: center;
    }
    
    .scanCardButtonMobile , .callCardButtonMobile ,.connectCardButtonMobile{
    padding:5px 10px;
    color:white;
    text-decoration: underline;
    text-decoration-color: white;
    font-weight: medium;
    border-style: none;
    margin:0px;
    }
    .scanCardButtonMobile{
        background-color: #FF6C2C;
        
    }
    .callCardButtonMobile{
        background-color: #945B9F
    }
    .connectCardButtonMobile{
     background-color: #5EB6E1;
    }
    

}


@media (max-width:280px){
    .cardsSection{
        display:none;
    }
    .cardSectionMobile{
        display:block;
       width:100%;
    margin-top:25px;
    margin-left:25px;

    }

    .scanCardMobile , .callCardMobile , .connectCardMobile{
        width:80%;
        height:90%;
        position:relative;
    }
    .scanCardContentMobile , .callCardContentMobile , .connectCardContentMobile{
        width:100%;
        height:inherit;
        font-size: 12px;
    }
 
}