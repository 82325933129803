.heading {
  width: fit-content;
  height: auto;
  overflow: hidden;
}
.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1%;
  width:100%
}
.headinglogo {
  width: 15%;
  height: 15%;
  padding-left: 1%;
  
}


.headerwhoositlogo {
  width: 100%;
  height: 50%;
}
.headerSectionRight{
  padding-right:20px;
}
.headerRight {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 8%;
  padding-right: 10%;
}
.headerContact {
  text-wrap: nowrap;
  font-size: 24px;
  font-weight: 600;
  white-space: nowrap;
}
.headeranchorfaq {
  font-size: 24px;
  font-weight: 600;

  text-decoration: underline;
  color: black;
}
.headerphone {
  width: 20px;
  height: 20px;
}
.headerUnlockPremium{
  text-wrap: nowrap;
  font-size: 24px;
  font-weight: 600;
  white-space: nowrap;

 
}
.headerUnlockPremiumLink{
  font-size: 24px;
  color:black
}

@media (max-width: 1024px) {
  .heading {
    max-width: fit-content;
    height: auto;
    overflow: hidden;
  }
  .headerphone {
    width: 20px;
    height: 20px;
  }

  .headerContact {
    text-wrap: nowrap;
    font-size: 20px;
    font-weight: 600;
  }
  .headeranchorfaq {
    font-size: 20px;
    font-weight: 600;
    text-decoration: underline;
  }
  .headinglogo {
    width: 30%;
    height: 25%;
  }
  .headerUnlockPremium{
    font-size: 20px;

  }
  .headerUnlockPremiumLink{
    font-size: 20px;
    color:black
  }
}

@media (max-width: 720px) {
  .heading {
    max-width: 100%;
    height: auto;
    overflow: hidden;
  }
  .headerphone {
    width: 15px;
    height: 15px;
  }
  .headerRight {
    column-gap: 4%;
    padding-right: 8%;
  }

  .headerContact {
    text-wrap: nowrap;
    font-size: 15px;
    font-weight: 600;
  }
  .headeranchorfaq {
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
  }
  .headinglogo {
    width: 30%;
    height: 25%;
  }
  .headerUnlockPremium{
    font-size: 15px;

  }
  .headerUnlockPremiumLink{
    font-size: 15px;
    color:black
  }
}

@media (max-width: 512px) {
  .heading {
    max-width: 100%;
    height: auto;
    overflow: hidden;
  }
  .headerphone {
    width: 14px;
    height: 14px;
  }

  .headerContact {
    text-wrap: nowrap;
    font-size: 12px;
    font-weight: 600;
  }
  .headeranchorfaq {
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
  }
  .headinglogo {
    width: 25%;
    height: 25%;
  }
  .headerUnlockPremium{
    font-size: 12px;

  }
  .headerUnlockPremiumLink{
    font-size: 12px;
    color:black
  }
}


@media (max-width: 380px) {
  .heading {
    max-width: 100%;
    height: auto;
    overflow: hidden;
  }
  .headerphone {
    width: 12px;
    height: 12px;
  }
  .headerRight {
    column-gap: 2%;
    padding-right: 3%;
  }

  .headerContact {
    text-wrap: nowrap;
    font-size: 10px;
    font-weight: 600;
  }
  .headeranchorfaq {
    font-size: 10px;
    font-weight: 600;
    text-decoration: underline;
  }
  .headinglogo {
    width: 25%;
    height: 20%;
  }
  .headerUnlockPremium{
    font-size: 10px;

  }
  .headerUnlockPremiumLink{
    font-size: 10px;
    color:black
  }
}
