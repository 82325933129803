.cardplanMain{
  width:100%;
  height:auto;
  overflow: hidden;
}
.cardplanheader{
  text-align: center;
  font-size: 40px;
  font-weight: 500;
}
.cardplanbluelinemark{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top:5px;
  padding-bottom: 10px;
}
.cardplanblueline{
  width:10%;
  height:2px;
  background-color: #5EB6E1;
  
}
.cardplanheader2{
  color:#FF6C2C;
  text-align: center;
  font-size: 25px;
  padding-top: 20px;
  padding-bottom: 50px;
}

.cardplansection{
  display:flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 3%;
  width:98%;
  height:100%;
  margin:1%

}
.cardplanTime{
  padding-top: 20px;
  padding-bottom: 20px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content:center;
  column-gap:3%;
}
.cardplanMonthly , .cardplanHalfYearly , .cardplanYearly{
  font-size: 28px;
  color:#212E61;
}
.cardplansection1 , .cardplansection2 ,.cardplansection3{
  width:25%;
  border-radius: 10px;
  padding:1%;
  padding-bottom: 4%;
  box-shadow: 2px 2px 2px rgba(34, 34, 34, 0.3);
}

.cardplansection1 , .cardplansection3{
  background-color: #F8F7FF;
}

.cardplansection2{
  background-color: #212E61;
  box-shadow: 2px 2px 2px rgba(34, 34, 34, 0.3);
}
.basicplanhead , .premiumplanhead ,.vipplanhead{
  font-size: 28px;
}
.premiumplanhead{
  color:white;
  margin-bottom: 0%;
}
.premiumplanpopular{
  padding:10px;
  background-image: linear-gradient(to right ,white, #FFEDB0);
  font-size: 24px;
  border-radius: 5px;
  color:black
}
.basicplanbluemark{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.basicplanblueline{
  width:100%;
  height:2px;
  background-color: #5EB6E1;
}
.basicplanContent , .premiumplanContent ,.vipplanContent{
  display:flex;
  flex-direction: column;
  row-gap:20px;
}
.premiumplanContent{
  color:white;
}
.basicplanprice , .premiumplanprice ,.vipplanprice{
  font-size: 22px;
  font-weight: 500;
}
.vipplandescription ,.premiumplandescription ,.basicplandescription{
  font-size: 20px;
}
.basicplanAddressVehicle ,.premiumplanAddressVehicle ,.vipplanAddressVehicle{
font-size: 16px;
font-weight: 500;

}
.basicplanaddresss , .basicplanvehicle , .basicplanmember ,.basicplandownload , .basicplandiscount ,.basicplangeneration , .basicplancall , 
.premiumplanaddresss , .premiumplanvehicle , .premiumplanmember ,.premiumplandownload , .premiumplandiscount ,.premiumplangeneration , .premiumplancall ,
.vipplanaddresss , .vipplanvehicle , .vipplanmember ,.vipplandownload , .vipplandiscount ,.vipplangeneration , .vipplancall {
padding-top: 10px;
}
.basicplanpremium , .premiumplanpremium ,.vipplanpremium{
  display:flex;

  align-items: center;
  justify-content: center;
  padding-top:17%;
}

.premiumplanAddressVehicle div,.basicplanAddressVehicle div, .vipplanAddressVehicle div {
  display:flex;
  align-items: baseline;
}

.premiumplanpremium{
  padding-top: 44%;
}
.premiumplanhead{
  display:flex;
  align-items: center;
  justify-content: space-between;
}
.basicplanpremiumbutton ,.premiumplanpremiumbutton ,.vipplanpremiumbutton{
  padding:10px 20px;
  font-size: 14px;
  border-radius: 10px;
  border-style: none;
  font-weight: 600;
  cursor:pointer

}
.basicplanpremiumbutton ,.vipplanpremiumbutton{
  background-color: #212E61;
 color: #F8F7FF;
}
.premiumplanpremiumbutton{
  color: #212E61;
  background-color: #F8F7FF;
}
@media (max-width:1393px){
  .basicplanhead , .premiumplanhead ,.vipplanhead{
    font-size: 22px;
  }
  .premiumplanpopular{
    
    font-size: 20px;
   
  }
  .basicplanprice , .premiumplanprice ,.vipplanprice{
    font-size: 18px;
    
  }
  .vipplandescription ,.premiumplandescription ,.basicplandescription{
    font-size: 16px;
  }
  .basicplanAddressVehicle ,.premiumplanAddressVehicle ,.vipplanAddressVehicle{
    font-size: 16px;
    
    }

}

@media (max-width:1133px){
  .basicplanhead , .premiumplanhead ,.vipplanhead{
    font-size: 18px;
  }
  .premiumplanpopular{
    
    font-size: 16px;
   
  }
  .basicplanprice , .premiumplanprice ,.vipplanprice{
    font-size: 16px;
    
  }
  .vipplandescription ,.premiumplandescription ,.basicplandescription{
    font-size: 14px;
  }
  .basicplanAddressVehicle ,.premiumplanAddressVehicle ,.vipplanAddressVehicle{
    font-size: 14px;
    
    }

}
@media (max-width:950px){
  .cardplanheader{
    font-size: 30px;
  }
  .cardplanblueline{
    width:15%;
  }
  .cardplanheader2{
    font-size: 22px;
  }

  .basicplanhead , .premiumplanhead ,.vipplanhead{
    font-size: 14px;
  }
  .premiumplanpopular{
    
    font-size: 12px;
   
  }
  .basicplanprice , .premiumplanprice ,.vipplanprice{
    font-size: 12px;
    
  }
  .vipplandescription ,.premiumplandescription ,.basicplandescription{
    font-size: 10px;
  }
  .basicplanAddressVehicle ,.premiumplanAddressVehicle ,.vipplanAddressVehicle{
    font-size: 10px;
    
    }
    .basicplanpremiumbutton ,.premiumplanpremiumbutton ,.vipplanpremiumbutton{
      font-size: 10px;
    }
}
@media (max-width:780px){
  .cardplansection{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;
    width:98%;
    height:100%;
    margin:1%;
    padding-top:10px;
    padding-bottom: 10px;
  
  }
  .cardplanheader{
    font-size: 24px;
    padding-top: 20px;
  }
  .cardplanblueline{
    width:15%;
  }
  .cardplanheader2{
    font-size: 18px;
  }
  .cardplansection1 , .cardplansection2 ,.cardplansection3{
    width:75%;
    height:100%;
    border-radius: 10px;
    padding:3%;
    padding-bottom: 4%;
    box-shadow: 2px 2px 2px rgba(34, 34, 34, 0.3);
  
  }

  .cardplansection2{
  box-shadow: 2px 2px 2px rgba(34, 34, 34, 0.3);
  }
  .basicplanhead , .premiumplanhead ,.vipplanhead{
    font-size: 24px;
  }
  .premiumplanpopular{
    
    font-size: 22px;
   
  }
  .basicplanprice , .premiumplanprice ,.vipplanprice{
    font-size: 22px;
    
  }
  .vipplandescription ,.premiumplandescription ,.basicplandescription{
    font-size: 22px;
  }
  .basicplanAddressVehicle ,.premiumplanAddressVehicle ,.vipplanAddressVehicle{
    font-size: 22px;
    
    }
}
@media (max-width:480px){
  .cardplanheader{
    font-size: 20px;
  }
  .cardplanblueline{
    width:20%;
  }
  .cardplanheader2{
    font-size: 16px;
  }

  .basicplanhead , .premiumplanhead ,.vipplanhead{
    font-size: 20px;
  }
  .premiumplanpopular{
    font-size: 16px;
  }
  .basicplanprice , .premiumplanprice ,.vipplanprice{
    font-size: 18px;
    
  }
  .vipplandescription ,.premiumplandescription ,.basicplandescription{
    font-size: 18px;
  }
  .basicplanAddressVehicle ,.premiumplanAddressVehicle ,.vipplanAddressVehicle{
    font-size: 18px;
    
    }
    .cardplanMonthly , .cardplanHalfYearly ,.cardplanYearly{
      font-size: 18px;
    }
}

@media (max-width:390px){
  .cardplansection1 , .cardplansection2 ,.cardplansection3{
    width:70%;
    height:100%;
    border-radius: 10px;
    padding:1%;
    padding-bottom: 4%;
  
  }
  .cardplanheader{
    font-size: 14px;
  }
  .cardplanblueline{
    width:25%;
  }
  .cardplanheader2{
    font-size: 10px;
  }

  .basicplanhead , .premiumplanhead ,.vipplanhead{
    font-size: 14px;
  }
  .premiumplanpopular{
    padding:2px;
    font-size: 14px;
   
  }
  .basicplanprice , .premiumplanprice ,.vipplanprice{
    font-size: 14px;
    
  }
  .vipplandescription ,.premiumplandescription ,.basicplandescription{
    font-size: 14px;
  }
  .basicplanAddressVehicle ,.premiumplanAddressVehicle ,.vipplanAddressVehicle{
    font-size: 14px;
    
    }
}

