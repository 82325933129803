.slider-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  margin: 0px;
  padding: 0px;
}

/* for 1st slide */
.slider {
  overflow: hidden;
}
.slider {
  width: 100%;
  height: 70%;
  margin: 0px;
  padding: 0px;
}
.slide1,
.slide2,
.slide3 {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.slideimg1,
.slideimg2,
.slideimg3 {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.slideimg1 ,.slideimg2 ,.slideimg3{
  position: relative;
  object-fit: fill;
}
/* 1st slide  */
.headerContentSlide1 ,.headerContentSlide2{
height:60%;
top: 20%;
bottom:20%;
position:absolute;
display:flex;
flex-direction: column;
row-gap: 8%;
align-items: left;
justify-content:center;
margin-left: 2%;
width:60vw;
}
.headerContentSlide2{
  width:40vw;
}
.content1slide1 {
  font-size: 32px;
  color: white;
  letter-spacing: 0.6px;
  opacity: 0.75;
  font-weight: 600;
  margin:0px;
}
.content2slide1 {
  font-size: 60px;
  font-weight: 600;
  color: white;
  letter-spacing: 0px;
  opacity: 1;
  margin:0px;
}

/* for 2nd slide */
.content1slide2 {
  font-size: 60px;
  color: #00569b;
  font-weight: 600;
  margin:0px;
}
.content2slide2 {
  font-size: 32px;
  color: black;
  font-weight: 600;
  margin:0px;
}

/* for 3rd slide  */
.headerContentSlide3{
  height:60%;
  top: 15%;
  bottom:15%;
  position:absolute;
  display:flex;
  flex-direction: column;
  row-gap: 4%;
  align-items: center;
  justify-content:center;
width:72vw;
  }
.content1slide3 {
  font-size: 60px;
  font-weight: 600;
  color: #ff7062;
  margin:0px;
}
.content2slide3 {
  font-size: 32px;
  font-weight: 600;
  color: #ff7062;
  margin:0px;
}
.content3slide3 {

  font-size: 28px;
  color: black;
  font-weight: 600;
  margin:0px;
  text-align: center;
}

.slider-container {
  position: relative;
}
/* for carousel button */
.custom-prev-arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
  z-index: 45;
  color: #212e61;
  transform: translateY(-50%);
  border-style: none;
  background-color: transparent;
}
.arrowleft {
  height: 30px;
  width: 30px;
  background-color: none;
}
.custom-next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
  z-index: 46;
  background-color: transparent;
  border-style: none;
}
.arrowright {
  height: 30px;
  width: 30px;
  background-color: none;
}
.custom-prev-arrow {
  left: 10px;
}
.custom-next-arrow {
  right: 10px;
}

@media(max-width:1800px){
  .content2slide1 {
    font-size: 50px;
  }
  .content1slide2 {
    font-size: 50px;
  }
  .content1slide3 {
    font-size: 50px;
  }
.content2slide3{
    font-size: 40px;
  }

  .content3slide3,  .content1slide1, .content2slide2 {
    font-size: 24px;
  }
}

@media(max-width:1180px){
  .content1slide3 , .content2slide1 , .content1slide2 {
    font-size: 35px;
  }
  .content2slide3{
    font-size: 25px;
  }
  .content3slide3 ,.content1slide1,.content2slide2 {
    font-size: 18px;
  }
}

@media(max-width:980px){
  .content1slide3 , .content2slide1 , .content1slide2 {
    font-size: 30px;
  }
  .content2slide3{
    font-size: 18px;
  }
  .content3slide3 ,.content1slide1,.content2slide2 {
    font-size: 14px;
  }
}
@media(max-width:650px){
  .content1slide3 , .content2slide1 , .content1slide2 {
    font-size: 22px;
  }
  .content2slide3{
    font-size: 16px;
  }
  .content3slide3 ,.content1slide1,.content2slide2 {
    font-size: 14px;
  }
  .arrowleft {
    height: 15px;
    width: 15px;
    background-color: none;
  }
  .arrowright {
    height: 15px;
    width: 15px;
    background-color: none;
  }
  .custom-prev-arrow {
    left: 5px;
  }
  .custom-next-arrow {
    right: 5px;
  }
}

@media(max-width:480px){

  .content1slide3 , .content2slide1 , .content1slide2 {
    font-size: 16px;
  }
  .content2slide3{
    font-size: 12px;
  }
  .content3slide3 ,.content1slide1,.content2slide2 {
    font-size: 10px;
  }
}

@media(max-width:360px){
  .headerContentSlide3{
    margin-left:0.5%
  }
  .content1slide3 , .content2slide1 , .content1slide2 {
    font-size: 16px;
  }
  .content2slide3{
    font-size: 10px;
  }

  .content3slide3 ,.content1slide1,.content2slide2 {
    font-size: 9px;
  }
  .custom-prev-arrow {
    left: 2px;
  }
}