.useCases{
    width:100%;
    height:auto;
    background-color: #222224;
    overflow: hidden;
}
.useCaseHeading{
  
   letter-spacing: 0px;
   color: #FFFFFF;
   opacity: 1;
   font-size: 40px;
   font-weight: 600;
   text-align: center;
   padding-top: 4%;
}
.useCaseHeader{
display:flex;
flex-direction: column;
row-gap:3% ;
}
.useCaseWrap{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:1%;
padding-top:0%;
}
.useCaseLine{
    width:8%;
    height:2px;
    background-color: skyblue;
  
}
.useCaseHeadingA, .useCaseHeadingB{
    text-align: center;
    padding:1%;
    color: #FFFFFF;
    font-size: 25px;
  
   letter-spacing: 0px;
   font-weight:600;
   /* text-transform: capitalize; */
   opacity: 1;
   padding:0px 40px;
}
.useCaseHeadingC{

letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
font-size: 30px;
font-weight: 600;
text-align: center;
padding-top: 1%;
padding-bottom:2%
}



.useCaseContent{
    width:100%;
    height:auto;
    border:1px solid black;
    display:grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    gap:10px;
} 

.useCasehoverlinemark{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.useCasehoverline{
    width:20%;
    height:2px;
    background-color: white;
    position:absolute;
}

 .useCaseContentItem1,.useCaseContentItem2,.useCaseContentItem3,.useCaseContentItem4,.useCaseContentItem5,.useCaseContentItem6{
    width:100%;
    height:100%;
    position:relative;
    
}
.useCaseContentItem6img,.useCaseContentItem5img,.useCaseContentItem4img, .useCaseContentItem3img,.useCaseContentItem2img,.useCaseContentItem1img{
    width:100%;
    height:100%;
    object-fit:cover;
    position:relative;
  
}
.useCaseContentItem1Content ,.useCaseContentItem2Content ,.useCaseContentItem3Content,.useCaseContentItem4Content ,.useCaseContentItem5Content ,.useCaseContentItem6Content{
    position:absolute;
    width:90%;
    height:9%;
    top:80%;
   text-align: center;
  background-color: rgba(0,0,0,0.6);
  color:white;
  font-size:24px;
  padding:5%;
 
}


.useCaseContentItem1 .hoverclass1, .useCaseContentItem2 .hoverclass2,.useCaseContentItem3 .hoverclass3,.useCaseContentItem4 .hoverclass4,.useCaseContentItem5 .hoverclass5,.useCaseContentItem6 .hoverclass6 {
    position:absolute;
    background-color:rgba(33,46,97, 0.5);
    width:90%;
    height:90%;
    border-radius: 0px;
   z-index: 7;
   top:5%;
   left:5%;


} 


.hoverclass1,.hoverclass2,.hoverclass3,.hoverclass4,.hoverclass5, .hoverclass6{

    display: none;
 
}
.useCasehoverclass1 , .useCasehoverclass2 , .useCasehoverclass3 ,.useCasehoverclass4 ,.useCasehoverclass5,.useCasehoverclass6{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: auto;
    width: 100%;
    height: 100%;

}


.hoverclass1head, .hoverclass2head ,.hoverclass3head ,.hoverclass4head ,.hoverclass5head ,.hoverclass6head{
    color:white;
   text-align: center;
   padding:10px;
   
   font-size: 40px;
}
.hoverclass1content ,.hoverclass2content , .hoverclass3content,.hoverclass4content,.hoverclass5content,.hoverclass6content{
    color:white;
    text-align: center;
    font-size: 22px;
  padding: "0px 10px";
}

.useCaseContentItem1:hover .hoverclass1{
 
    display:block;

}

.useCaseContentItem2:hover .hoverclass2{
    display:block;

}
.useCaseContentItem3:hover .hoverclass3{
    display:block;

}
.useCaseContentItem4:hover .hoverclass4{
    display:block;

}
.useCaseContentItem5:hover .hoverclass5{
    display:block;

}
.useCaseContentItem6:hover .hoverclass6{
    display:block;

}


.popuplinemark{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 1%;
    padding-bottom: 3%;
}
.popupline{
    width:15%;
    height:2px;
    background-color:white;
  
}
.custom-arrow-featureSlider {
    display:block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    outline: none;
    transition: background-color 0.3s;
    z-index: 1;
    background: transparent;
  }
  
  .custom-arrowSlider :hover {
    background-color: #555;
  }
  
  
  
  .previousfeatureSlider {
    left: -30px;
   z-index: 40;
  }
  
  .nextfeatureSlider {
    right: -20px;
  }
  .arrowleftfeatureSlider ,.arrowrightfeatureSlider{
    height:20px;
    width:20px;
  }

  .popupContentContainer {
    max-height: 650px; /* Adjust the height as needed */
    overflow-y: auto;
  }
  .popupContentContainer::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
.useCaseContentItem1:hover .useCaseContentItem1Content ,.useCaseContentItem2:hover .useCaseContentItem2Content ,.useCaseContentItem3:hover .useCaseContentItem3Content,.useCaseContentItem4:hover .useCaseContentItem4Content,.useCaseContentItem5:hover .useCaseContentItem5Content,.useCaseContentItem6:hover .useCaseContentItem6Content{
    display:none;
}

.ourusecaseCarousel{
    display:none;
}





@media (max-width:1570px){
    .hoverclass1head, .hoverclass2head ,.hoverclass3head ,.hoverclass4head ,.hoverclass5head ,.hoverclass6head{
        color:white;
       text-align: center;
       padding:5px;
      
       font-size: 26px;
    }
    .hoverclass1content ,.hoverclass2content , .hoverclass3content,.hoverclass4content,.hoverclass5content,.hoverclass6content{
        color:white;
        font-size: 22px;
    }
}


@media (max-width:1350px){
    .hoverclass1head, .hoverclass2head ,.hoverclass3head ,.hoverclass4head ,.hoverclass5head ,.hoverclass6head{
        color:white;
       text-align: center;
       padding:5px;
      
       font-size: 22px;
    }
    .hoverclass1content ,.hoverclass2content , .hoverclass3content,.hoverclass4content,.hoverclass5content,.hoverclass6content{
        color:white;
        font-size: 18px;
    }
}
@media (max-width:1280px){
    .hoverclass1head, .hoverclass2head ,.hoverclass3head ,.hoverclass4head ,.hoverclass5head ,.hoverclass6head{
        color:white;
       text-align: center;
       padding:5px;

       font-size: 22px;
    }
    .hoverclass1content ,.hoverclass2content , .hoverclass3content,.hoverclass4content,.hoverclass5content,.hoverclass6content{
        color:white;
        font-size: 18px;
    }
}
@media (max-width:1190px){
    .hoverclass1head, .hoverclass2head ,.hoverclass3head ,.hoverclass4head ,.hoverclass5head ,.hoverclass6head{
        color:white;
       text-align: center;
       padding:5px;
    
       font-size: 22px;
    }
    .hoverclass1content ,.hoverclass2content , .hoverclass3content,.hoverclass4content,.hoverclass5content,.hoverclass6content{
        color:white;
        font-size: 16px;
    }
}

@media (max-Width:1024px){
    .useCases{
        display:none;
    }
    .ourusecaseCarousel{
        display:block;
    }
    .ourusecaseCarousel{
        width:100%;
        height:20%;
        background-color: #222224;
    }
    .ourusecaseCarouselHeadingA{
        text-align: center;
        color: #FFFFFF;
        padding:10px;
        padding-bottom: 2px;
        margin-bottom: 0px;
        font-weight:600;
        font-size: 30px;
    }

    /* for blue line */
    .ourusecaseCarouselline{
display:flex;
flex-direction: row;
align-items: center;
justify-content: center;
    }

   .ourusecaseCarouselblueline{
    width:10%;
    height:2px;
    background-color: skyblue;
    margin-bottom: 10px;
   }
.ourusecaseCarouselHeadingB{
    font-size:20px;
    font-weight: 600;
    color:white;
    text-align: center;
    padding:10px;
    padding-bottom: 5px;
}
.ourusecaseCarouselHeadingC{
    font-size: 25px;
 
    color:white;
    text-align: center;
    padding:10px;
    padding-bottom: 5px;
}
.ourusecaseslide{
    margin:0px;
    width:100%;
    height:60%;
    padding:0px;
}
.carparkslide, .modernhouseslide, .shotmanslide ,.girlwithsunglassesslide ,.industrialparkslide,.buildingslide{
    width:100%;
    height:100%;
    margin:0%;
    padding:0px;
    position:relative
}
.carparkslideimg, .modernhouseslideimg, .shotmanslideimg ,.girlwithsunglassesslideimg ,.industrialparkslideimg,.buildingslideimg{
    width:100%;
    height:100%;
    object-fit: fill;
    margin:0%;
    padding:0px;
    position:relative;
}
.carparkcontent ,.modernhousecontent ,.girlwithsunglassescontent ,.shotmancontent ,.industrialparkcontent ,.buildingcontent {
display:flex;
flex-direction:column;
align-items: center;
justify-content: center;
    width:95%;
    height:90%;
    margin:2%;
    margin-bottom: 3%;
    position:absolute;
    top:1%;
    background-color: rgba(33,46,97,0.5);
    
}
.carparkcontentHead ,.modernhouseHead ,.girlwithsunglassesHead ,.shotmanHead ,.industrialparkHead ,.buildingHead{
    text-align: center;
    color:white;
    font-size: 28px;
    font-weight: 500;
    margin:0px;
}
.ourusecasewhitelinemark{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100%;
    height:2px
}
.ourusecasewhiteline{
    width:20%;
    height:2px;
    background-color:white;
}
.carparkcontentpara , .modernhousecontentpara ,.girlwithsunglassescontentpara ,.shotmancontentpara ,.industrialparkcontentpara ,.buildingcontentpara{
    font-size: 20px;
}
.Vehicleviewmorebutton , .hotelsviewmorebutton ,.girlwithsunglassesviewmorebutton ,.shotmanviewmorebutton ,.industrialparkviewmorebutton ,.buildingviewmorebutton{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding:5px;
  
}
.vehicleviewmore , .hotelsviewmore ,.girlwithsunglassesviewmore ,.shotmanviewmore ,.industrialparkviewmore ,.buildingviewmore{
    border-style: none;
    font-size: 20px;
    color:white;
    background-color: transparent;
    cursor: pointer;
    text-align: center;
}

}

@media(max-width:786px){
    .carparkcontentpara , .modernhousecontentpara ,.girlwithsunglassescontentpara ,.shotmancontentpara ,.industrialparkcontentpara ,.buildingcontentpara{
        font-size: 14px;
    }
}
@media (max-width:601px){
    .carparkcontentHead ,.modernhouseHead ,.girlwithsunglassesHead ,.shotmanHead ,.industrialparkHead ,.buildingHead{
        text-align: center;
        color:white;
        font-size: 22px;
        font-weight: 500;
        margin:0px;
    }
    .carparkcontentpara , .modernhousecontentpara ,.girlwithsunglassescontentpara ,.shotmancontentpara ,.industrialparkcontentpara ,.buildingcontentpara{
        font-size: 12px;
    }
    .ourusecaseCarouselblueline{
        width:15%;
        height:2px;
        background-color: skyblue;
        margin-bottom: 10px;
       }
       
}
@media (max-width:530px){
    .custom-arrow-featureSlider {
        display:none;
    }
    .carparkcontentHead ,.modernhouseHead ,.girlwithsunglassesHead ,.shotmanHead ,.industrialparkHead ,.buildingHead{
        text-align: center;
        color:white;
        font-size: 24px;
        font-weight: 500;
        margin:0px;
    }
    .carparkcontentpara , .modernhousecontentpara ,.girlwithsunglassescontentpara ,.shotmancontentpara ,.industrialparkcontentpara ,.buildingcontentpara{
        font-size: 20px;
    }
    .ourusecaseCarouselblueline{
        width:15%;
        height:2px;
        background-color: skyblue;
        margin-bottom: 10px;
       }
       
}
@media (max-width:420px){
    .carparkcontentHead ,.modernhouseHead ,.girlwithsunglassesHead ,.shotmanHead ,.industrialparkHead ,.buildingHead{
        text-align: center;
        color:white;
        font-size: 22px;
        font-weight: 500;
        margin:0px;
    }
    .carparkcontentpara , .modernhousecontentpara ,.girlwithsunglassescontentpara ,.shotmancontentpara ,.industrialparkcontentpara ,.buildingcontentpara{
        font-size: 14px;
    }
    .ourusecaseCarouselblueline{
        width:15%;
        height:2px;
        background-color: skyblue;
        margin-bottom: 10px;
       }
       
}

@media (max-width:300px){
    .carparkcontentHead ,.modernhouseHead ,.girlwithsunglassesHead ,.shotmanHead ,.industrialparkHead ,.buildingHead{
        text-align: center;
        color:white;
        font-size: 20px;
        font-weight: 500;
        margin:0px;
    }
    .carparkcontentpara , .modernhousecontentpara ,.girlwithsunglassescontentpara ,.shotmancontentpara ,.industrialparkcontentpara ,.buildingcontentpara{
        font-size: 12px;
    }
    .ourusecaseCarouselblueline{
        width:15%;
        height:2px;
        background-color: skyblue;
        margin-bottom: 10px;
       }
       
}