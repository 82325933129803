.howitworks {
  width: 100%;
  margin-top: 80px;
  overflow: hidden;
}

.howitworksHeadingA {
  text-align: center;
  font-size: 40px;
  color: #212e61;
  padding: 0px;
  margin: 0px;
  font-weight: 600;
}
.howitworksHeadingB {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  color: #545455;
}
.worksblueline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-bottom: 10px;
}
.howitworksblueunderline {
  width: 10%;
  height: 2px;
  background-color: skyblue;
}
.howitworksvideoContainer {
  width: 100%;
  height: 100%;
  margin-top: 70px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.reactplayervideo {
  border: 10px solid rgb(255, 255, 255);
  border-radius: 20px;
  outline: 2px solid rgb(0, 60, 128);
}

.howitworksvideo {
  height: 40vh;
  width: 40%;
  min-width: 300px;
  min-height: 400px;
}

@media (max-width: 920px) {
  .howitworks {
    margin-top: 80px;
  }
  .howitworksHeadingA {
    font-size: 40px;
  }
  .howitworksHeadingB {
    font-size: 30px;
  }

  .howitworksblueunderline {
    width: 12%;
    height: 2px;
    background-color: skyblue;
  }
  .howitworksvideoContainer {
    margin-top: 50px;
    margin-bottom: 70px;
  }

  .howitworksvideo {
    height: 40vh;
    width: 70%;
    min-width: 300px;
    min-height: 400px;
  }
}
@media (max-width: 678px) {
  .howitworksHeadingA {
    font-size: 30px;
  }
  .howitworksHeadingB {
    font-size: 25px;
  }
}
@media (max-width: 480px) {
  .howitworks {
    margin-top: 80px;
  }
  .howitworksHeadingA {
    font-size: 24px;
  }
  .howitworksHeadingB {
    font-size: 18px;
  }
  .howitworksblueunderline {
    width: 12%;
    height: 2px;
    background-color: skyblue;
  }
  .howitworksvideoContainer {
    margin-top: 50px;
    margin-bottom: 90px;
  }
  .howitworksvideo {
    height: 30vh;
    width: 70%;
    min-width: 280px;
    min-height: 350px;
  }
}

@media (max-width: 280px) {
  .howitworksHeadingA {
    font-size: 18px;
  }
  .howitworksHeadingB {
    font-size: 12px;
  }
  .howitworksblueunderline {
    width: 14%;
    height: 2px;
    background-color: skyblue;
  }
  .howitworksvideoContainer {
    margin-top: 50px;
    margin-bottom: 90px;
  }
  .howitworksvideo {
    height: 40vh;
    width: 50%;
    min-width: 200px;
    max-height: 250px;
  }
}
