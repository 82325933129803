.contactSection {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(22, 24, 32);
  display: flex;
  flex-direction: row;
}
.contactform {
  width: 50%;
  height: 100%;
}
.contactimage {
  width: 50%;
  height: 100%;
}
.contactusimage {
  width: 100%;
  height: 100%;
  
}
.contactform {
  padding-top: 5%;
  padding-left: 10%;
}
.contactform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.inputfeild {
  width: 425px;
  height: 50px;
  border: 2px solid skyblue;
  background-color: rgba(0, 0, 0, 0);
  row-gap: 20px;
  color:white;

}
input::placeholder {
  padding-left: 10px;
}
textarea::placeholder {
  padding-left: 10px;
  padding-top: 10px;
}
.contactheading span {
  font-weight: 600;
  font-size: xx-large;
  color: white;
}
.contactheadingunderline {
  text-decoration: underline;
  text-decoration-color: skyblue;
}
.inputfeildbutton {
  width: 425px;
  height: 50px;
  border: 1px solid skyblue;
  border-width: 1px;
  background-color: skyblue;
  border-radius: 10px;
}
.inputfeildbutton .feildbutton {
  font-size: larger;
  font-weight: 600;
  text-decoration: underline;
}
.contactdetails {
  font-size: large;
  font-weight: 600;
  color: white;
}
.socialmedia {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 20px;
  padding-bottom: 20px;
}

/* blue underline */
.contactblueunderline {
  width: 10%;
  height: 2px;
  background-color: skyblue;
  margin-top: 10px;
  margin-bottom: 10px;
}
.footerQuickContactquickcontact1 {
  color: grey;
  padding-bottom: 2px;
}
/* .rc-anchor-light{
  background-color: black;
} */

.error-message{
  margin:0;
  color:red;
}

 

@media (max-width:1024px) {
  .contactSection {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
  .contactform {
    padding-top: 10%;
    padding-left:0%;
  }

  .contactform {
    width: 100%;
    height: auto;
  }
  .contactimage {
    width: 100%;
    height: auto;
  }
 
  .contactform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inputfeild {
    width: 400px;
    height: 50px;
    border: 2px solid skyblue;
    background-color: rgba(0, 0, 0, 0);
    row-gap: 20px;

  }

  .inputfeildbutton {
    width: 400px;
    height: 50px;
    border: 1px solid skyblue;
    border-width: 1px;
    background-color: skyblue;
    border-radius: 10px;
  }
  .contactblueunderline {
    width: 15%;
    height: 2px;
    background-color: skyblue;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: 480px) {
  .inputfeildbutton {
    width: 300px;
    height: 50px;
    border: 1px solid skyblue;
    border-width: 1px;
    background-color: skyblue;
    border-radius: 10px;
  }
  .contactform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inputfeild {
    width: 300px;
    height: 50px;
    border: 2px solid skyblue;
    background-color: rgba(0, 0, 0, 0);
    row-gap: 20px;
  }
  .contactdetails {
    font-size: 15px;
    font-weight: 3px;
    color: white;
  }
  .contactblueunderline {
    width: 30%;
    height: 2px;
    background-color: skyblue;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width:330px) {
  form{
    padding-left: 40px;
    padding-right: 20px;
  }
  .inputfeildbutton {
    width: 260px;
    height: 50px;
    border: 1px solid skyblue;
    border-width: 1px;
    background-color: skyblue;
    border-radius: 10px;
  }
  .contactform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inputfeild {
    width: 260px;
    height: 50px;
    border: 2px solid skyblue;
    background-color: rgba(0, 0, 0, 0);
    row-gap: 20px;
  }
  .contactdetails {
    font-size: 15px;
    font-weight: 10px;
    color: white;
  }
  .contactblueunderline {
    width: 30%;
    height: 2px;
    background-color: skyblue;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .recaptcha{
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
   
  }
}
@media (max-width:280px) {
  form{
    margin-left: 30px;
    padding-right: 20px;
  }
  .inputfeildbutton {
    width: 230px;
    height: 50px;
    border: 1px solid skyblue;
    border-width: 1px;
    background-color: skyblue;
    border-radius: 10px;
  }
  .contactform {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .inputfeild {
    width: 230px;
    height: 50px;
    border: 2px solid skyblue;
    background-color: rgba(0, 0, 0, 0);
    row-gap: 20px;
  }
  .contactdetails {
    font-size: 10px;
    font-weight: 5px;
    color: white;
  }
  .contactblueunderline {
    width: 30%;
    height: 2px;
    background-color: skyblue;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
