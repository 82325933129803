.footerSection {
  width: 100%;
  overflow: hidden;
}
.footerSection1 {
  height: auto;
}

.footerSection1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 8%;
  padding-top: 8%;
  padding-left: 8%;
  padding-bottom: 8%;
  padding-right: 3%;

  background-color: #19191c;
  color: white;
}
.footerwhoositlogoSection{
  width:fit-content;
  margin: 0px;
 
}
.footerwhoositlogo {
  width: 50%;
  height: 30%;
  padding-bottom: 2%;
}
.footerwhoositlogoimg {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.footerwhoosittext {
  font-size: 26px;

  color: white;
  padding-bottom: 3%;
}
.footerdownloads {
  display: flex;
  flex-direction: row;
  column-gap: 3%;
}
.footerQuickContacts,
.footervision {
  display: flex;
  flex-direction: column;
  row-gap: 8%;
}
.footervision {
  row-gap: 2%;
}

.footerQuickContact,
.footerourvision {
  font-size: 26px;
  color: rgb(255, 255, 255);
  padding-bottom: 2%;
  font-weight: 600;
}
.footerQuickContactquickcontact1{
  font-size: 22px;
}
.footerQuickContactquickcontact2{
  font-size: 22px;
  color:grey;
}
.footerQuickContactquickcontact3{
  font-size: 22px;
  color:grey;
}
.footerourvisionContent{
  font-size: 22px;
}

.footerlogos {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.footerSection2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 3%;
}
.footerSection2 {
  height: 10%;
  padding: 20px;
  background-color: #5eb6e1;
}
.termcondition,
.privatepolicy {
  font-size: 24px;
  text-decoration: underline;
  font-weight: 600;
}
.tandcText , .privacyText{
  font-size: 20px;
}

.footerSection3 {
  height: 20%;
  padding: 20px;

  background-color: #24272e;
  color: white;
  font-weight: lighter;
}
.footerSection3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sysquarelink {
  text-decoration: underline;
  color: #5eb6e1;
}
.copyright{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1064px) {
  .footerwhoositlogo {
    width: 100%;
    height: 40%;
    padding-bottom: 2%;
    background: transparent;
  }
  .footerwhoositlogoimg {
    width: 30%;
    height: 30%;
    object-fit: contain;
  }
}
@media (max-width: 780px) {
  .footerSection1 {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
  
  .footerwhoositlogo {
    width: 100%;
    height: 30%;
    padding-bottom: 2%;
    background: transparent;
  }
  .footerQuickContact,
  .footerourvision {
    font-size: 26px;
    color: rgb(255, 255, 255);
    padding-bottom: 4%;
    font-weight: 600;
  }
}
@media (max-width:560px){
 .copyright{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }
 .footerwhoositlogoimg {
  width: 50%;
  height: 40%;
  object-fit: fill;
}
}
@media (max-width: 480px) {
  .footerdownloads {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .footerQuickContact,
  .footerourvision {
    font-size: 24px;
    color: rgb(255, 255, 255);
    padding-bottom: 4%;
    font-weight: 600;
  }
  .sysquarelink {
    white-space: nowrap;
  }
  .copyright {
    font-size: 12px;
  }
  .footerwhoositlogoimg {
    width: 50%;
    height: 40%;
    object-fit: fill;
  }
  .tandcText , .privacyText{
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .tandcText,
  .privacyText {
    font-size: 12px;
  }
  .footerSection2 {
    padding: 5px;
  }
  .footerwhoosittext {
    font-size: 22px;
  }  
  .sysquarelink {
    white-space: nowrap;
  }
  .copyright {
    font-size: 10px;
  }
  .footerSection3 {
    padding: 5px;
  }
  .footerwhoositlogoimg {
    width: 100%;
    height: 40%;
    object-fit: fill;
  }
  .footerQuickContactquickcontact1{
    font-size: 18px;
  }
  .footerQuickContactquickcontact2{
    font-size: 18px;
    color:grey;
  }
  .footerQuickContactquickcontact3{
    font-size: 18px;
    color:grey;
  }
  .footerourvisionContent{
    font-size: 18px;
  }
  
}
@media (max-width: 290px){
  .footerQuickContact,
  .footerourvision {
    font-size: 22px;}
  .footerQuickContactquickcontact1{
  font-size: 16px;
}
.footerQuickContactquickcontact2{
  font-size: 16px;
  color:grey;
}
.footerQuickContactquickcontact3{
  font-size: 16px;
  color:grey;
}
.footerourvisionContent{
  font-size: 16px;
}
}