.FaqSection {
  width: 100%;
  overflow: hidden;
}

.underlinestyled {
  width: 7%;
  height: 2px;
  background-color: skyblue;
  align-items: center;
  margin-bottom: 20px;
}
.faqHeadingCenter {
  margin: 0px;
  padding: 0px;
}
.faqheading {
  font-size: 40px;
  font-weight: 600;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 540px) {
  .underlinestyled {
    width: 30%;
    height: 2px;
    background-color: skyblue;
    align-items: center;
    margin-bottom: 20px;
  }
  .faqheading {
    font-size: 20px;
    font-weight: 600;
  }
}
